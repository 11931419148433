<template>
  <div>
    <edit-form
      type="edit"
      @onsave="onEdit"
    />
  </div>
</template>
<script>
export default {
  components: {
    editForm: () => import("../utils/Form.vue"),
  },
  data() {
    return {};
  },

  methods: {
    onEdit(params) {
      const payload = { ...params }; //clone
      payload.is_active = payload.is_active == "active" ? "1" : "0";
      payload.route_id = this.$route.params.id;
      payload.return_to_depot = payload.return_to_depot == true ? true : false;
      payload.optimize_sequence = payload.optimize_sequence == true ? true : false;
      this.$store
        .dispatch("routeListing/update", payload)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>
